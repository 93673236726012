.contact {
  width: 100%;
  height: 100vh;
  background: rgb(248, 235, 235);
}
a {
  color: rgb(82, 76, 76);
  
 
}
.form {
 padding-top: 150px;
}
.inputs {
  width: 60%;
  height: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: rgb(82, 76, 76);
  }
  input {
    width: 100%;
    padding: 20px;
    margin-block: 0.5rem;
    outline: none;
    border: none;
  }
  .message {
    padding-bottom: 10rem;
  }
  button {
    padding: 10px;
    background: none;
    cursor: pointer;
    margin-top: 1rem;
    border: 1px solid pink;
    color: rgb(82, 76, 76);
    text-transform: uppercase;
    font-weight: bolder;
 
  }
}

@media (max-width: 550px) {
  .inputs {
    width: 80%;

    input {
      padding: 15px;
    }
  }
}
