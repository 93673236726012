.main {
    min-height: 100vh;
    max-width:100%;
    background-color: #f8efef;
    position: relative;

    &::before, &::after{
        content: '';
        position: absolute;
        left: 0;
    }
    &::before{
        clip-path: polygon(100% 33%, 0% 100%, 100% 100%);
        background: linear-gradient(45deg, rgba(216,46,8,0.085),rgba(62,7,189,0.216));
        width: 100%;
        height: 100%;
    }
}
.main__container {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.main__contain {
    margin-left: 3.445rem;
}
.text {
    line-height: 55px;
     p{
         color: rgb(157,155,155);
         text-transform: uppercase;
         font-size: 22px;
     }
     h1 {
         font-size: 50px;
         text-transform: uppercase;
     }
}
.icons{
    display: flex;
    .icon{
        margin-top: 1rem;
        margin-right: 1.4rem;
        font-size: 35px;
        cursor: pointer;
        color: rgb(157, 155, 155);
        &:hover{
            color:rgb(80, 77, 77);
        }
    }
}
.buttons {
    button {
        padding: 15px 40px;
        margin-top: 2rem;
        margin-right: 1rem;
        background: linear-gradient(25deg,#833524,#a88de5);
        cursor: pointer;
        color:#fff;
        border-radius: 5px;
        border: 1px solid #fff;
        text-transform: uppercase;
        font-weight: bolder;
    }
}
.main__img {
  
    clip-path: circle(45% at 50% 49%) ;
    cursor: pointer;
    img {
        background: linear-gradient(45deg,#833524,#a88de5);
        
    }
}
@media (max-width: 1200px){
    .text {
        line-height: 30px;
        white-space: nowrap;
        p{
            font-size: 14px;
        }
        h1{
            font-size: 30px;
        }
    }
    .buttons{
        button{
            padding: 8px 20px;
            font-weight: normal;
        }
    }
    .icons{
       .icon{
           font-size: 25px;
       }
    }
  
}
@media (max-width:600px){
    .main__contain{
        margin-left: 0;
    }
    .main__container{
        text-align: center;
        flex-direction: column-reverse;
        justify-content: center;
    }
    .main__img {
        //margin-right: 0;
        width: 250px;
    }
    .text {
        h1 {
            font-size: 23px;
        }
        p{
            font-size: 11px;
        }
    }
    .icons {
        margin-left: 3rem;
    }
}