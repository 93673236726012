.header {
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;
}
.header__logo {
    margin-left: 3.445rem;
}
.header__title{
    font-family: 'Allura', cursive;
    font-size: 3em;
    padding: 10px;
    color: rgb(157, 155, 155);
}
.navbar {
    margin: 0 auto;
}
ul {
    display: flex;
    li{
        list-style: none;
        margin: 1rem;
        a {
            text-decoration: none;
            text-transform: uppercase;
            color: black;
            &:hover {
                border-bottom: 3px solid rgb(157, 155, 155);
            }
        }
    }
}
.changer {
    display: flex;
    margin-left: 1.555rem;
    margin-right: 0.5rem;
    .menu {
        cursor: pointer;
        display: none;
    }
}
.closed {
    .close {
        display: none;
    }
}
@media (max-width:768px){
    .header{
        justify-content: space-between;
    }
    .navbar{
        display: none;
    }
    .navbar.active {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        inset: 0;
        background: rgb(253,234,234);
    }
    ul {
        margin: 0 auto;
        align-items: center;
        flex-direction: column;
        margin-top: 1rem;
        li {
            margin-block: 2rem;
            a{
                font-size: 20px;
                color: rgb(40,36,36);
                font-weight: bolder;
            }
        }
    }
    .changer {
        .menu {
            display: block;
        }
    }
    .closed {
        .close{
            display: block;
        }
    }
}